import React from "react"
import { graphql } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Button, useMediaQuery } from "@material-ui/core"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import LineTitle from "../components/LineTitle"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Youtube from "../components/Video/Youtube"
import HorizontalScroll from "../components/horizontalScroll"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"
import before from "../images/before-facelift.webp"
import after from "../images/after-facelift.webp"
import jacbefore from "../images/before-after/jaquelin-lateral-new-before.webp"
import jacafter from "../images/before-after/jaquelin-lateral-new-after.webp"
import jacfrontbefore from "../images/before-after/jaquelin-front-before.webp"
import jacfrontafter from "../images/before-after/jaquelin-front-after.webp"
import jac34before from "../images/before-after/jaquelin-34-before.webp"
import jac34after from "../images/before-after/jaquelin-34-after.webp"
import barbaraBefore from "../images/before-after/barbara-34-before.webp"
import barbaraAfter from "../images/before-after/barbara-34-after.webp"
import it34before from "../images/before-after/it-34-before.webp"
import it34After from "../images/before-after/it-34-after.webp"
import khan34Before from "../images/before-after/khan-34-new-before.webp"
import khan34After from "../images/before-after/khan-34-new-after.webp"
import barbaraLateralBefore from "../images/before-after/barbara-lateral-new-before2.webp"
import barbaraLateralAfter from "../images/before-after/barbara-lateral-new-after2.webp"
import mkLAteralBefore from "../images/before-after/mk-lateral-new-before.webp"
import mkLAteralAfter from "../images/before-after/mk-lateral-new-after.webp"
import angelineBA from "../images/before-after/angeline-lateral.webp"
import eliseLateralBefore from "../images/before-after/elise-lateral-before.webp"
import eliseLateralAfter from "../images/before-after/elise-lateral-after.webp"
import ewa34Before from "../images/before-after/ewa-34-before.webp"
import ewa34After from "../images/before-after/ewa-34-after.webp"
import marleenAfter from "../images/before-after/marleen-lateral-after.webp"
import marleenBefore from "../images/before-after/marleen-lateral-before.webp"
import igLateralBefore from "../images/before-after/ig-lateral-before.webp"
import igLateralAfter from "../images/before-after/ig-lateral-after.webp"
import mariaBefore from "../images/before-after/maria-front-before.webp"
import mariaAfter from "../images/before-after/maria-front-after.webp"

// import QAAccordion from "../components/accordion"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
      height: "42vh",
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  bodyHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 600,
    color: "#202020",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
  figText: {
    fontSize: "1.1rem",
    textAlign: "left",
    fontStyle: "italic",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    // paddingBottom: 20,
    [theme.breakpoints.up("md")]: {
      // paddingTop: 20,
    },
  },
  figText2: {
    fontSize: "1.1rem",
    textAlign: "left",
    fontStyle: "italic",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    [theme.breakpoints.up("lg")]: {
      marginLeft: -30,
    },
  },
  bodyText2: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "#202020",
    maxWidth: 600,
    whiteSpace: "pre-line",
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  introHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
      paddingTop: 40,
    },
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "auto",
      paddingBottom: "auto",
    },
  },
  tooLongtitle: {
    color: "white",
    maxWidth: 200,
    ["@media (min-width:1440px)"]: {
      fontSize: "5rem",
    },
    ["@media (max-width:600px)"]: {
      fontSize: "2rem",
    },
  },
  tooLongSubheader: {
    color: "#575757",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  videoText: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  textSectionText: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
    fontSize: "1.9rem",
    ["@media (min-width:759px)"]: {
      fontSize: 34,
    },
  },
  scrollTitle: {
    paddingTop: 140,
    textAlign: "left",
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
    ["@media (max-width:600px)"]: {
      paddingTop: 80,
    },
  },
  scrollText: {
    paddingTop: 30,
    textAlign: "left",
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
    fontSize: "1.6rem",
    ["@media (max-width:600px)"]: {
      fontSize: "0.9rem",
    },
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right",
      // height: 260,
      height: "280px",
    },
  },
  qaContainer: {
    paddingTop: 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
  // imageContainer: {
  //   paddingTop: 0,
  //   [theme.breakpoints.down("md")]: {
  //     paddingTop: 0,
  //   },
  // },
  // imageContainerBeforeAfter: {
  //   [theme.breakpoints.down("md")]: {},
  // },
  videoContainer: {
    // maxHeight: 400,
    // maxHeight: 300,
    height: 460,
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
  sectionContainer: {
    paddingTop: 80,
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  videoWrapper: {
    marginTop: 60,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      // paddingTop: 40,
      // paddingBottom: 0,
    },
  },
  spacer: {
    height: 40,
  },
  spacerSmall: {
    height: 20,
  },
  introContainer: {
    paddingTop: 100,
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  bNAContainer: {
    paddingTop: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
    },
  },
  scrollSection: {
    paddingTop: -20,
    paddingBottom: 60,
    marginBottom: 140,
  },
  beforeAndAfter: {
    marginTop: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
  beforeAfter: {
    maxWidth: 290,
    // marginBottom: 100,
    // [theme.breakpoints.down("xl")]: {
    //   maxWidth: 330,
    // },
  },
  sectionContainerExplanation: {
    paddingTop: 60,
    paddingBottom: 30,
    background: "white",
    // background: "#343640",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  faceList: {
    fontSize: "1.1rem",
    color: "#202020",
    maxWidth: 600,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    textAlign: "left",
  },
  questions: {
    fontSize: "0.9rem",
    textAlign: "left",
    color: "#929B91",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 10,
  },
  helpOutline: {
    color: "#929B91",
  },
  faq: {
    color: "#929B91",
    fontSize: "1.1rem",
    fonWeight: "bold",
  },
  qaList: {
    paddingBottom: 10,
  },
  layers: {
    maxWidth: 500,
    [theme.breakpoints.down("md")]: {
      width: 340,
    },
  },
  outroContainer: {
    // background: "#BABFB9",
    background: "white",
  },
  //   customCompareSlider img {
  //  pointerEvents: none
  //   }
  customCompareSlider: {
    "& [data-rcs='root']": {
      touchAction: "pan-y !important",
    },
    "& img": {
      pointerEvents: "none",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 40,
    },
  },
  customCompareSliderWithTextSection: {
    maxWidth: "400px",
    height: "100%",
    flexGrow: 1,
    touchAction: "pan-y",
    marginRight: "11%",
    "& [data-rcs='root']": {
      touchAction: "pan-y !important",
    },
    "& img": {
      pointerEvents: "none",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 40,
      marginRight: 0,
    },
    // Add any additional theme-based styles if necessary
  },
  bookNowButton: {
    marginTop: 20,
    marginRight: 80,
    color: "#fff!important",
    fontSize: "16px!important",
    backgroundColor: "#929B91",
    borderRadius: "40px!important",
    padding: "1.0em 4em!important",
    textDecoration: "none!important",
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "24px!important",
      padding: "0.8em 1.2em!important",
      marginRight: 0,
    },
  },
}))

const DeepPlane = ({ intl, data, location }) => {
  const classes = useStyles()
  const theme = useTheme()
  const t = (id) => intl.formatMessage({ id })
  const banner = data.bannerImage.childImageSharp.fluid
  const scrollImages = [
    data.itLateral,
    data.it34,
    data.mkLateral,
    // data.msAngelineLateral,
    // data.msAngelineFront,
    // data.msAngeline34,
    data.jaqLateral,
  ]
  const faceExplained = [
    t("deepPlane.faceExplained.1"),
    t("deepPlane.faceExplained.2"),
    t("deepPlane.faceExplained.3"),
    t("deepPlane.faceExplained.4"),
  ]
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "deepPlane.SEOtitle" })}
        description={intl.formatMessage({ id: "deepPlane.SEO" })}
        path={location.pathname}
      />
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <section>
          <BackgroundImage
            fluid={banner}
            className={classes.backgroundImg}
            backgroundColor={`#333`}
          >
            <Grid container spacing={0} className={classes.bannerGrid}>
              <Grid item xs={12} sm={4} className={classes.bannerLeft}>
                <Container>
                  <LineTitle
                    titleContainer={classes.titleContainer}
                    header={t("deepPlane.title")}
                    titleStyle={classes.tooLongtitle}
                    lightDecoLine
                    subHeaderStyle={classes.tooLongSubheader}
                  />
                </Container>
              </Grid>
              <Grid item xs={12} sm={8}></Grid>
            </Grid>
          </BackgroundImage>
          <div className={classes.spacer} />
          <Grid container spacing={12} className={classes.introContainer}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h3"
                className={classes.introHeader}
              >
                {t("deepPlane.introTitle")}
              </Typography>
              <p className={classes.bodyText}>{t("deepPlane.introText")}</p>
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Img
                fluid={data.it34.childImageSharp.fluid}
                alt="before and after deep plane facelift example"
              />
              <p className={classes.figText}>
                Pre and Post-Facelift: Drs. Callum Faris's Artistry in Facial
                Rejuvenation. Example 1
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={12} className={classes.introContainer}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h3"
                className={classes.introHeader}
              >
                {t("deepPlane.introCasesTitle")}
              </Typography>
              {/* <p className={classes.bodyText}>
                {t("deepPlane.introCasesText")}
              </p> */}
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <p className={classes.bodyText}>
                {t("deepPlane.introCasesText")}
              </p>
              {/* <Img
                fluid={data.it34.childImageSharp.fluid}
                alt="before and after deep plane facelift example"
              />
              <p className={classes.figText}>
                Pre and Post-Facelift: Dr. Callum Faris's Artistry in Facial
                Rejuvenation. Example 1
              </p> */}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={12}
            className={classes.bNAContainer}
            style={{ touchAction: "pan-y" }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              // style={{ paddingBottom: "40px" }}
            >
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      // src={data.beforeFacelift.childImageSharp.fluid}
                      src={barbaraAfter}
                      // srcSet="..."
                      alt="Deep plane facelift example 2 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      // src={data.afterFacelift.childImageSharp.fluid}
                      src={barbaraBefore}
                      // srcSet="..."
                      alt="Deep plane facelift example 2 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  An extended deep plane facelift and deep neck lift showcase a
                  beautifully natural rejuvenation. One of our classic plans for
                  a timeless transformation.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      // src={data.beforeFacelift.childImageSharp.fluid}
                      src={it34After}
                      // srcSet="..."
                      alt="Deep plane facelift example 3 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      // src={data.afterFacelift.childImageSharp.fluid}
                      src={it34before}
                      // srcSet="..."
                      alt="Deep plane facelift example 3 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  A comprehensive panfacial approach with an extended deep plane
                  facelift, deep neck lift, endoscopic brow lift, fat grafting,
                  and a lip lift. The result is a full-face harmonious,
                  refreshed appearance that enhances youthful contours.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      // src={data.beforeFacelift.childImageSharp.fluid}
                      src={jacbefore}
                      // srcSet="..."
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      // src={data.afterFacelift.childImageSharp.fluid}
                      src={jacafter}
                      // srcSet="..."
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  Another panfacial approach with an extended deep plane
                  facelift, deep neck lift, endoscopic brow lift, and full face
                  fat grafting, this transformation celebrates restored volume
                  and lifted features.
                </p>
              </div>
            </Grid>
          </Grid>

          {/* VIDEO */}
          <div className={classes.spacer} />
          <div className={classes.spacer} />
          <Grid container spacing={12} className={classes.videoWrapper}>
            <Grid
              item
              xs={12}
              sm={6}
              align="center"
              className={classes.videoContainer}
            >
              <Youtube
                videoUrl="https://www.youtube.com/watch?v=Pjsr7wclc9Q"
                imgFluid={data.explained.childImageSharp.fluid}
                imgFluidMobile={data.explainedMobile.childImageSharp.fluid}
              />
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.videoText}
              >
                {t("deepPlane.videoText")}
              </Typography>
              <Button
                className={classes.bookNowButton}
                data-leadbox-popup="NADZexEWCBXrfX2vRpaATf"
                data-leadbox-domain="kooldigital.lpages.co"
              >
                Book appointment
              </Button>
            </Grid>
          </Grid>
          <div className={classes.spacerSmall} />
          <div className={classes.spacer} />

          <Grid
            container
            spacing={12}
            className={classes.bNAContainer}
            style={{ touchAction: "pan-y" }}
          >
            <Grid item xs={12} sm={4} align="center">
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      src={khan34After}
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={khan34Before}
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  Facial fillers were dissolved to reset the canvas, followed by
                  pan facial fat grafting and an extended deep plane facelift
                  and neck lift. The outcome is a sophisticated, sculpted, and
                  naturally rejuvenated look.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      src={barbaraLateralAfter}
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={barbaraLateralBefore}
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  Another beautiful example of our extended deep plane facelift
                  and deep neck lift. A refined, refreshed result from one of
                  our signature plans.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      // src={data.beforeFacelift.childImageSharp.fluid}
                      src={mkLAteralBefore}
                      // srcSet="..."
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      // src={data.afterFacelift.childImageSharp.fluid}
                      src={mkLAteralAfter}
                      // srcSet="..."
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  Our second most common plan: a tailored combination of an
                  extended deep plane facelift, deep neck lift, and strategic
                  fat grafting to restore youthful fullness and definition.
                </p>
              </div>
            </Grid>
          </Grid>

          {/* Natural result text section */}
          <div className={classes.spacer} />
          <Grid
            container
            spacing={12}
            className={classes.bNAContainer}
            style={{ touchAction: "pan-y" }}
          >
            <Grid item xs={12} sm={6} align="center" alignContent="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.textSectionText}
              >
                {t("deepPlane.naturalResultsWith")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} align="right" paddingRight="40px">
              <div className={classes.customCompareSliderWithTextSection}>
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      src={mariaBefore}
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={mariaAfter}
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText}>
                  Our most basic plan: Combining an extended deep plane
                  facelift, a traditional neck lift and lip lift.
                </p>
              </div>
            </Grid>
          </Grid>
          <div className={classes.spacer} />

          {/* Vika swipe setti */}
          <Grid
            container
            spacing={12}
            className={classes.bNAContainer}
            style={{ touchAction: "pan-y" }}
          >
            <Grid item xs={12} sm={4} align="center">
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      src={eliseLateralBefore}
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={eliseLateralAfter}
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  Our most requested plan: an endoscopic brow lift (result not
                  shown), extended deep plane facelift, deep neck lift, and fat
                  grafting. The result exudes a lifted, refreshed, and
                  effortlessly youthful look.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      src={ewa34After}
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={ewa34Before}
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  Our third most common plan: An extended deep plane facelift,
                  neck lift, and fat grafting result in an elegant, rejuvenated
                  appearance with refined contours and volume restoration.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <div
                style={{
                  maxWidth: "400px",
                  height: "100%",
                  flexGrow: 1,
                  touchAction: "pan-y",
                }}
                className={classes.customCompareSlider}
              >
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      src={marleenBefore}
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={marleenAfter}
                      // srcSet="..."
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText2}>
                  Our second most common plan: Featuring an extended deep plane
                  facelift, deep neck lift, and fat grafting. The outcome is a
                  stunning balance of definition, volume, and graceful
                  rejuvenation.
                </p>
              </div>
            </Grid>
          </Grid>

          {/*Last Book now section */}
          <div className={classes.spacer} />
          <Grid
            container
            spacing={12}
            className={classes.bNAContainer}
            style={{ touchAction: "pan-y" }}
          >
            <Grid item xs={12} sm={6} align="center" alignContent="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.textSectionText}
              >
                {t("deepPlane.makeDeepAppointment")}
              </Typography>
              <Button
                className={classes.bookNowButton}
                data-leadbox-popup="NADZexEWCBXrfX2vRpaATf"
                data-leadbox-domain="kooldigital.lpages.co"
              >
                Book consultation
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} align="right" paddingRight="40px">
              <div className={classes.customCompareSliderWithTextSection}>
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      src={igLateralBefore}
                      alt="Deep plane facelift example 4 before"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={igLateralAfter}
                      alt="Deep plane facelift example 4 after"
                      style={{ pointerEvents: "none", touchAction: "pan-y" }}
                    />
                  }
                  onlyHandleDraggable={true}
                />
                <p className={classes.figText}>
                  Our most basic plan: Combining an extended deep plane facelift
                  and a traditional neck lift to reveal beautifully sculpted
                  contours.
                </p>
              </div>
            </Grid>
          </Grid>
          <div className={classes.spacer} />

          {/* // Doubble images */}
          {/* <Grid
            container
            spacing={12}
            className={classes.bNAContainer}
            style={{ touchAction: "pan-y" }}
          >
            <Grid item xs={12} sm={6} align="center">
              <Img
                fluid={data.ewa34.childImageSharp.fluid}
                alt="before and after deep plane facelift"
              />
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Img
                fluid={data.eliseLateralBA.childImageSharp.fluid}
                alt="before and after deep plane facelift"
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.bNAContainer}
            style={{ touchAction: "pan-y" }}
          >
            <Grid item xs={12} sm={6} align="center">
              <Img
                fluid={data.marleenLateral.childImageSharp.fluid}
                alt="before and after deep plane facelift"
              />
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Img
                fluid={data.incognito34.childImageSharp.fluid}
                alt="before and after deep plane facelift"
              />
            </Grid>
            <Grid item xs={12} sm={6} align="center"></Grid>
          </Grid> */}

          {/* HorizontalScroller */}
          {/* <Grid container spacing={12} className={classes.beforeAndAfter}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h3"
                className={classes.scrollTitle}
              >
                Natural Results
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} align="center"></Grid>
          </Grid> */}
          {/* <HorizontalScroll images={scrollImages} /> */}
          <Grid container spacing={12} className={classes.scrollSection}>
            {/* <Grid item xs={12} sm={6} align="center"> */}
            {/* <QAAccordion></QAAccordion> */}
            {/* </Grid> */}
            {/* <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.scrollText}
              >
                Scroll for more <br />
                before and after >>
              </Typography>
            </Grid> */}
          </Grid>

          {/* Help Outline QA */}
          {/* <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid item xs={12} sm={6} align="center">
            <div className={classes.qaList}>
              <HelpOutline fontSize="large" className={classes.helpOutline} />
              <p className={classes.faq}>{t("deepPlane.qa")}</p>
            </div>
            <p className={classes.questions}>{t("deepPlane.questions")}</p>
          </Grid>
          <Grid item xs={12} sm={6} align="center"></Grid>
        </Grid> */}
        </section>
        <section>
          <Grid
            container
            spacing={12}
            direction={smallScreen ? "column-reverse" : "row"}
            className={classes.sectionContainerExplanation}
          >
            <Grid item xs={12} sm={8} align="center">
              <Typography
                component="h2"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("deepPlane.explainedTitle")}
              </Typography>
              <p className={classes.bodyText2}>{t("deepPlane.introExplain")}</p>
              <ol className={classes.faceList}>
                {faceExplained.map((parts) => (
                  <li>{parts}</li>
                ))}
              </ol>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.qaContainer}
            >
              <div className={classes.qaList}>
                {/* <HelpOutline fontSize="large" className={classes.helpOutline} /> */}
                <p className={classes.faq}>{t("deepPlane.qa")}</p>
              </div>
              <p className={classes.questions}>{t("deepPlane.questions")}</p>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid item xs={12} sm={8} align="center">
              <p className={classes.bodyText2}>
                {t("deepPlane.faceStructures")}
              </p>
              <p className={classes.bodyText2}>{t("deepPlane.layers")}</p>
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <Img
                fluid={data.layers.childImageSharp.fluid}
                alt="Face layers"
                className={classes.layers}
              ></Img>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid item xs={12} sm={8} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("deepPlane.fourthLayerTitle")}
              </Typography>
              <p className={classes.bodyText2}>
                {t("deepPlane.fourthLayerFirst")}
              </p>
              <p className={classes.bodyText2}>
                {t("deepPlane.fourthLayerSecond")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.qaContainer}
            >
              <Img
                fluid={data.skinFourthLayer.childImageSharp.fluid}
                alt="Face fourth layers"
                className={classes.layers}
              ></Img>
              <Img
                fluid={data.skinLayer.childImageSharp.fluid}
                alt="Skin layers"
                className={classes.layers}
              ></Img>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid
              item
              xs={12}
              sm={8}
              align="center"
              className={classes.qaContainer}
            >
              <p className={classes.bodyText2}>
                {t("deepPlane.fourthLayerThird")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.qaContainer}
            >
              <Img
                fluid={data.faceLigament.childImageSharp.fluid}
                alt="Face fourth layers"
                className={classes.layers}
              ></Img>
              {/* <Img
              fluid={data.faceLigamentTwo.childImageSharp.fluid}
              alt="Skin layers"
              className={classes.layers}
            ></Img> */}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid item xs={12} sm={8} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("deepPlane.whatLayerTitle")}
              </Typography>
              <p className={classes.bodyText2}>
                {t("deepPlane.whatLayerFirst")}
              </p>
              <p className={classes.bodyText2}>
                {t("deepPlane.fourthLayerSecond")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.qaContainer}
            >
              <Img
                fluid={data.levelsDeepPlane.childImageSharp.fluid}
                alt="Face fourth layers"
                className={classes.layers}
              ></Img>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={12}
            className={classes.sectionContainerExplanation}
          >
            <Grid
              item
              xs={12}
              sm={8}
              align="center"
              className={classes.qaContainer}
            >
              <p className={classes.bodyText2}>
                {t("deepPlane.whatLayerSecond")}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.qaContainer}
            >
              <Img
                fluid={data.results.childImageSharp.fluid}
                alt="Face fourth layers"
                className={classes.layers}
              ></Img>
            </Grid>
          </Grid>

          <Grid container spacing={12} className={classes.outroContainer}>
            <Grid item xs={12} sm={8} align="center">
              <Typography
                component="h3"
                variant="h4"
                className={classes.bodyHeader}
              >
                {t("deepPlane.outroTitle")}
              </Typography>
              <p className={classes.bodyText2}>{t("deepPlane.outroFirst")}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="center"
              className={classes.qaContainer}
            >
              {/* <Img
              fluid={data.results.childImageSharp.fluid}
              alt="Face fourth layers"
              className={classes.layers}
            ></Img> */}
            </Grid>
          </Grid>
        </section>
      </Container>
    </Layout>
  )
}

export default injectIntl(DeepPlane)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "HeroBannerDeepPlane3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    afterFacelift: file(relativePath: { eq: "after-facelift.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeFacelift: file(relativePath: { eq: "before-facelift.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    msAngeline34: file(
      relativePath: { eq: "before-after-ms-angeline-34.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    msAngelineLateral: file(relativePath: { eq: "ms-angeline-lateral.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    msAngelineFront: file(relativePath: { eq: "ms-angeline-front.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mkLateral: file(relativePath: { eq: "mk-lateral.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    itLateral: file(relativePath: { eq: "before-after/it-lateral.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    it34: file(relativePath: { eq: "before-after/it-34.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jaqLateral: file(relativePath: { eq: "jacqueline-lateral.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    front: file(relativePath: { eq: "front-min.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    side: file(relativePath: { eq: "side-min.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    layers: file(relativePath: { eq: "layers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skinFourthLayer: file(relativePath: { eq: "skin-fourth-layer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skinLayer: file(relativePath: { eq: "skin-layer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faceLigament: file(relativePath: { eq: "face-ligament.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faceLigamentTwo: file(relativePath: { eq: "face-ligament-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    levelsDeepPlane: file(relativePath: { eq: "levels-deep-plane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    results: file(relativePath: { eq: "operation-results-deep-facelift.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    explained: file(relativePath: { eq: "deep-plane-facelift-explained.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    explainedMobile: file(
      relativePath: { eq: "deep-plane-facelift-explained-mobile.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jaquelin34: file(
      relativePath: { eq: "jaquelin-34-before-after-new.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jaquelinlateral: file(
      relativePath: { eq: "before-after/jaquelin-lateral-before-after.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    angelineLateralBA: file(
      relativePath: { eq: "before-after/angeline-lateral.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eliseLateralBA: file(
      relativePath: { eq: "before-after/elise-lateral.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ewa34: file(relativePath: { eq: "before-after/ewa-34-lf.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ewaLateral: file(relativePath: { eq: "before-after/ewa-lateral-lf.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marleenLateral: file(
      relativePath: { eq: "before-after/marleen-lateral.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    khaan34: file(relativePath: { eq: "before-after/khaan-34.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    incognito34: file(relativePath: { eq: "before-after/incognito-34.webp" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
