import React, { useEffect, useRef, useState } from "react"
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@material-ui/core"
import Img from "gatsby-image"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  scrollContainer: {
    display: "flex",
    overflowX: "auto",
    gap: "30px",
    padding: "10px",
    width: "100%",
    scrollBehavior: "smooth", // Enables smooth scrolling
  },
  imageWrapper: {
    width: "800px",
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "340px",
    },
  },
  scrollImage: {
    objectFit: "cover",
    width: "100%",
    height: "auto",
  },
  scrollButton: {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(255,255,255,0.5)", // Semi-transparent
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.7)", // Slightly less transparent on hover
    },
    zIndex: 10, // Ensure it's above other content
  },
}))

const HorizontalScroll = ({ images }) => {
  const classes = useStyles()
  const theme = useTheme()
  const scrollRef = useRef(null)
  const [isHovering, setIsHovering] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const scrollAmount = isMobile ? 340 : 800

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current && !isHovering) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current
        const firstImageWidth = scrollRef.current.firstChild
          ? scrollRef.current.firstChild.offsetWidth + 30
          : 0 // 30px is the gap size
        const isAtEnd = scrollLeft + clientWidth >= scrollWidth

        if (isAtEnd) {
          // Scroll to start if at the end
          scrollRef.current.scrollTo({ left: 0, behavior: "smooth" })
        } else {
          // Scroll by the width of the first image
          scrollRef.current.scrollTo({
            left: scrollLeft + firstImageWidth,
            behavior: "smooth",
          })
        }
      }
    }, 3000) // Change scroll position every 3 seconds

    return () => clearInterval(interval) // Cleanup interval on component unmount
  }, [isHovering])

  //Works on mac
  const handleScrollClick = () => {
    if (!scrollRef.current) return

    const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current
    // Assuming the first image width represents a standard image width including the gap
    const imageWidthWithGap = scrollRef.current.firstChild
      ? scrollRef.current.firstChild.offsetWidth + 30
      : 0

    // Calculate the remaining distance to the end of the scroll container
    const remainingDistanceToEnd = scrollWidth - (scrollLeft + clientWidth)

    // If clicking the button would not fully reveal the last image, scroll to reveal as much as possible without overshooting
    if (
      remainingDistanceToEnd > 0 &&
      remainingDistanceToEnd < imageWidthWithGap
    ) {
      // Scroll just enough to get to the end, revealing the last image fully
      scrollRef.current.scrollBy({
        left: remainingDistanceToEnd,
        behavior: "smooth",
      })
    } else if (remainingDistanceToEnd <= 0) {
      // If we've reached or overshot the end, reset to start
      scrollRef.current.scrollTo({ left: 0, behavior: "smooth" })
    } else {
      // In all other cases, scroll by one standard image width
      scrollRef.current.scrollBy({
        left: imageWidthWithGap,
        behavior: "smooth",
      })
    }
  }

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.scrollContainer}
        ref={scrollRef}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {images.map((image, index) => (
          <div key={index} className={classes.imageWrapper}>
            <Img
              key={index}
              // src={image}
              fluid={image.childImageSharp.fluid}
              alt={`before and after deep plane facelift ${index}`}
              className={classes.scrollImage}
            />
          </div>
        ))}
        <IconButton
          onClick={handleScrollClick}
          className={classes.scrollButton}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default HorizontalScroll
